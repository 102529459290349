
import { throwError as observableThrowError, forkJoin as observableForkJoin, Observable, of } from "rxjs";
import { Component, ViewChild, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { ProductRateService } from "../../finance/product-rate/product-rate.service";
import { ProjectTaskProductRateService } from "./project-task-product-rate.service";
import { ProjectTaskService } from "../project-task/project-task.service";
import { ProductService } from "../../codebook/product/product.service";
import { EmployeeService } from "../employee/employee.service";
import { AccountCenterService } from "../../organization/account-center/account-center.service";
import { TimeService, PermissionService, ToastService } from "../../core/services";
import { filterAndDebounce } from "../../core/utils";
import { CompanyService } from "../../crm/company/company.service";
import { AppService } from "../../app.service";
import { TableBaseComponent } from "../../core/index";
import { ListQueryParams } from "../../models/list-query-params.model";
import { Immutable } from "../../core/immutable";
import { ProjectTaskFileService } from "../project-task/project-task-file.service";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { finalize, catchError, switchMap } from "rxjs/operators";
import { InvoiceService } from '../../finance/invoice/invoice.service';
import { StripDiacriticsPipe } from "../../shared/pipes/strip-diacritics.pipe";

class QueryParams extends ListQueryParams {
    accountCenterCode: string;
    technicianId: number;
    technicianName: string;
    companyId: number;
    companyTitle: string = null;
    year: number;
    month: number;
    hasPapersCompleted: boolean;
    hasNotApprovedRates: boolean;
    isReadyToInvoice: boolean;
    isReadyToPartiallyInvoice: boolean;
    isNotInvoiced: boolean;
    projectTaskId: number;
    countryCode: string;
    from: Date;
    to: Date;
}

@Component({
    selector: "op-project-task-product-rate-invoice",
    templateUrl: "./project-task-product-rate-invoice.component.html"
})
export class ProjectTaskProductRateInvoiceComponent extends TableBaseComponent {
    isLoading = false;
    queryParams = new QueryParams();
    model: PagedList<app.operation.ProjectTaskProductRateInvoiceViewModel>;
    modelNotPaged: PagedList<app.operation.ProjectTaskProductRateInvoiceViewModel>;
    selectedTechnician: app.operation.EmployeeSelectListViewModel;
    selectedCompany: app.crm.CompanySelectListViewModel;
    selectedProjectTask: app.operation.ProjectTaskSelectListViewModel;
    accountCenters: app.organization.AccountCenterSelectListViewModel[];
    years: number[];
    months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    currentUser: app.UserViewModel;
    objectKey: string;
    objectKeyEstimate: string;
    isAllExpanded = false;
    modalRef: NgbModalRef;
    invoiceNote: string;
    selectedForInvoiceNote: app.operation.ProjectTaskProductRateInvoiceViewModel;
    filter: any;
    sumPayers: number = 0;
    sumNonPayers: number = 0;
    isLoadingCalc: boolean = false;
    numberOfRecords: number = 0;
    stripDiacritics = new StripDiacriticsPipe();
    pageSizess = [20, 50, 100, 200];
    selectedItemsIds = [];
    isAllChecked = false;

    constructor(router: Router,
        private projectTaskProductRateService: ProjectTaskProductRateService,
        private appService: AppService,
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private timeService: TimeService,
        private permissionService: PermissionService,
        private projectTaskService: ProjectTaskService,
        private accountCenterService: AccountCenterService,
        private changeDetectorRef: ChangeDetectorRef,
        private projectTaskFileService: ProjectTaskFileService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private invoiceService: InvoiceService,
        private modalService: NgbModal) {
        super(router);
        this.currentUser = appService.homeViewModel.user;
        this.years = timeService.getYears();
        this.queryParams.sortBy = this.sortBy = "fullProjectTaskNumber";
        this.objectKey = projectTaskProductRateService.objectKey;
        this.objectKeyEstimate = "op.ProjectTaskProductRateEstimate";
        this.onReset();
    }

    ngOnInit() {

        super.ngOnInit();
   
        this.accountCenterService.getSelectList().subscribe((result) => {
            this.accountCenters = result;
        });

        this.invoiceService.getFilter().subscribe(res => {
            this.filter = res;            
        });

        if (this.hasPreviousState) {
            if (this.queryParams.technicianId) {
                this.selectedTechnician = <app.operation.EmployeeSelectListViewModel>{
                    employeeId: this.queryParams.technicianId,
                    employeeName: this.queryParams.technicianName
                };
            }
            if (this.queryParams.companyId) {
                this.selectedCompany = <app.crm.CompanySelectListViewModel>{
                    companyId: this.queryParams.companyId,
                    titleWithAddressCity: this.queryParams.companyTitle
                };
            }
        } else {
            this.queryParams.accountCenterCode = this.currentUser.accountCenterCode;
            this.onReset();
        }

        this.loadData();
    }

    loadData() {
        this.selectedItemsIds = [];
        this.isLoading = true;

        if (this.selectedTechnician) {
            this.queryParams.technicianId = this.selectedTechnician.employeeId;
            this.queryParams.technicianName = this.selectedTechnician.employeeName;
        }
        else {
            this.queryParams.technicianId = null;
            this.queryParams.technicianName = null;
        }

        if (this.selectedCompany) {
            this.queryParams.companyId = this.selectedCompany.companyId;
            this.queryParams.companyTitle = this.selectedCompany.titleWithAddressCity;
        }
        else {
            this.queryParams.companyId = null;
            this.queryParams.companyTitle = null;
        }        

        this.projectTaskProductRateService.getInvoice(this.queryParams)
            .pipe(finalize(() => {
                this.isLoading = false;
                this.isAllChecked = false;
            }))
            .subscribe(res => {
                this.model = res;               
                this.saveState(this.queryParams);
            });
    }

    onReset() {
        this.onClear();
        this.queryParams.accountCenterCode = this.currentUser.accountCenterCode;
        const date = new Date();
        this.queryParams.year = date.getFullYear();
        this.queryParams.month = date.getMonth() + 1;
        this.onMonthChange(this.queryParams.month);
    }

    onClear() {
        this.selectedTechnician = null;
        this.selectedCompany = null;
        this.queryParams.year = null;
        this.queryParams.month = null;
        this.queryParams.from = null;
        this.queryParams.to = null;
        this.queryParams.isNotInvoiced = true;
        this.queryParams.page = 1;
        this.queryParams.pageSize = 20;
    }

    employeeAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.employeeService.getTechnicianList(1, 20, this.stripDiacritics.transform(term))));
    }

    employeeFormatter = (employee: app.operation.EmployeeSelectListViewModel) => employee.employeeName;

    companyAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.companyService.getList(1, 20, this.stripDiacritics.transform(term))));
    }

    companyFormatter = (company: app.crm.CompanySelectListViewModel) => company.titleWithAddressCity;

    get canChangeIsReadyToInvoice() {
        return this.permissionService.hasPermission("fin.Invoice", "Read");
    }

    get canChangeIsMarkedToInvoice() {
        return this.permissionService.hasPermission("fin.Invoice", "Insert");
    }

    toggle(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { isExpanded: !item.isExpanded });

        this.toggleFiles(this.model.data[index]);
    }

    toggleAllItems() {
        this.isAllExpanded = !this.isAllExpanded;
        for (let item of this.model.data) {
            let index = this.model.data.indexOf(item);
            this.model = Immutable.setInPagedList(this.model, index, { isExpanded: this.isAllExpanded });
        }
    }

    toggleFiles(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        let index = this.model.data.indexOf(item);
        let showFiles = this.toggleShowFiles(this.model.data[index]);

        if (showFiles && item["filesContent"] == null) {
            this.model = Immutable.setInPagedList(this.model, index, { filesLoading: true });

            observableForkJoin(this.sharePointLoadData(item), this.sharePointLoadUrl(item))
                .pipe(finalize(() => {
                    this.model = Immutable.setInPagedList(this.model, index, { filesLoading: false });
                }))
                .subscribe(result => {
                    this.onFilesChange(this.model.data[index], { data: result[0], sharePointUrl: result[1].url });
                });
        }
    }

    toggleShowFiles(item: app.operation.ProjectTaskProductRateInvoiceViewModel): boolean {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { showFiles: !item["showFiles"] });

        return this.model.data[index]["showFiles"];
    }

    sharePointLoadData(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        return this.projectTaskFileService.query({ page: 1, pageSize: 1000, sortBy: "lastUpdatedUtc desc", forInv: true, projectTaskId: item.projectTaskId });
    }

    sharePointLoadUrl(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        if (this.appService.isSharepointEnabled) {
            return this.projectTaskFileService.getSharePointFolder(item.projectTaskId);
        } else {
            return of({ url: null });
        }
    }

    changeIsReadyToInvoice($event: boolean, item: app.operation.ProjectTaskProductRateInvoiceViewModel, checkbox) {
        if (!this.canChangeIsReadyToInvoice) {
            checkbox.checked = !$event;
            return;
        }

        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToInvoice: true });

        if ($event) {
            this.projectTaskService.setIsReadyToInvoice(item.projectTaskId, item.hasForInvFiles)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isReadyToInvoice: $event }));
        } else {
            this.projectTaskService.setIsNotReadyToInvoice(item.projectTaskId, item.invoiceRejectNote)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isReadyToInvoice: $event }));
        }
    }

    changeIsReadyToPartiallyInvoice($event: boolean, item: app.operation.ProjectTaskProductRateInvoiceViewModel, checkbox) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToPartiallyInvoice: true });

        if ($event) {
            this.projectTaskService.setIsReadyToPartiallyInvoice(item.projectTaskId, item.hasForInvFiles)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToPartiallyInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isReadyToPartiallyInvoice: $event }));
        } else {
            this.projectTaskService.setIsNotReadyToPartiallyInvoice(item.projectTaskId, item.invoicePartiallyRejectNote)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsReadyToPartiallyInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isReadyToPartiallyInvoice: $event }));
        }
    }

    changeIsMarkedToInvoice($event: boolean, item: app.operation.ProjectTaskProductRateInvoiceViewModel, checkbox) {
        if (!this.canChangeIsMarkedToInvoice) {
            checkbox.checked = !$event;
            return;
        }

        let index = this.model.data.indexOf(item);

        this.model = Immutable.setInPagedList(this.model, index, { changingIsMarkedToInvoice: true });

        if ($event) {
            this.projectTaskService.setIsMarkedToInvoice(item.projectTaskId)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsMarkedToInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isMarkedToInvoice: $event }));
        } else {
            this.projectTaskService.setIsNotMarkedToInvoice(item.projectTaskId)
                .pipe(
                    catchError((err) => {
                        checkbox.checked = !$event;
                        return observableThrowError(err);
                    })
                    ,
                    finalize(() => this.model = Immutable.setInPagedList(this.model, index, { changingIsMarkedToInvoice: false })))
                .subscribe(() => this.model = Immutable.setInPagedList(this.model, index, { isMarkedToInvoice: $event }));
        }
    }

    onFilesCountChange(item: app.operation.ProjectTaskProductRateInvoiceViewModel, $event: number) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { hasForInvFiles: $event > 0 });
    }

    onFilesChange(item: app.operation.ProjectTaskProductRateInvoiceViewModel, $event: any) {
        let index = this.model.data.indexOf(item);
        this.model = Immutable.setInPagedList(this.model, index, { filesContent: $event });
    }

    openInvoiceNoteModal(content, item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        this.selectedForInvoiceNote = item;
        this.invoiceNote = item.invoiceNote;
        this.modalRef = this.modalService.open(content);
    }

    updateInvoiceNote() {
        this.isLoading = true;

        this.projectTaskService.updateInvoiceNote(this.selectedForInvoiceNote.projectTaskId, this.invoiceNote)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                let index = this.model.data.indexOf(this.selectedForInvoiceNote);
                this.model = Immutable.setInPagedList(this.model, index, { invoiceNote: this.invoiceNote });
                this.invoiceNote = null;
                this.modalRef.close();
            });
    }

    getPlusMinusColClass = () => {
        return { "text-center": true };
    }

    onInvoice($event) {
        let projectTasksToInvoice = this.invoiceProjectTasks;

        if (projectTasksToInvoice.length === 0) {
            this.translateService.get("invoice.project-task.empty").subscribe(res => {
                this.toastService.error(res);
            });
            $event.preventDefault();
            $event.stopPropagation();
            return false;
        }

        if (projectTasksToInvoice.length > 1) {
            let companyId = projectTasksToInvoice[0].companyId;

            let allSameCompany = projectTasksToInvoice.every((item) => {
                return item.companyId === companyId;
            });

            if (!allSameCompany) {
                this.translateService.get("invoice.project-task.not-same-company").subscribe(res => {
                    this.toastService.error(res);
                });
                $event.preventDefault();
                $event.stopPropagation();
                return false;
            }
        }
    }

    onCalcEstimation() {
        this.isLoadingCalc = true;
        let params = { ...this.queryParams };
        this.sumNonPayers = 0;
        this.sumPayers = 0;
        params.pageSize = 99999999;

        this.projectTaskProductRateService.getInvoice(params)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.isLoading = true;
                this.modelNotPaged = res;
                this.numberOfRecords = this.modelNotPaged.data.length;
                let sumPay: number = 0, sumNoPay: number = 0;

                var withSk = this.modelNotPaged.data.filter(a => a.vatIdNumber != null && a.vatIdNumber.indexOf("SK") != -1);
                var notWithSK = this.modelNotPaged.data.filter(a => (a.vatIdNumber != null && a.vatIdNumber.indexOf("SK") == -1) || a.vatIdNumber == null);

                withSk.forEach(function (entry) {
                    entry.productRates.forEach(function (childrenEntry) {
                        sumPay = sumPay + childrenEntry.priceTotal;                        
                    });
                });
                notWithSK.forEach(function (entry) {
                    entry.productRates.forEach(function (childrenEntry) {
                        sumNoPay = sumNoPay + childrenEntry.priceTotal;
                    });
                });

                this.sumPayers = sumPay;
                this.sumNonPayers = sumNoPay;
                this.isLoadingCalc = false;
            });
        this.loadData();
        
    }

    get invoiceProjectTasks() {
        return this.model ? this.model.data.filter(x => x.isMarkedToInvoice) : [];
    }

    get invoiceProjectTaskIds() {
        return this.invoiceProjectTasks.map(x => x.projectTaskId);
    }

    get invoiceUrl() {
        return this.router.createUrlTree(["/finance/invoice/create"], { queryParams: { projectTaskIds: this.invoiceProjectTaskIds } });
    }

    projectTaskAutocomplete = (text$: Observable<string>) => {
        return text$
            .pipe(filterAndDebounce,
                switchMap(term => term.length < 2 ? of([]) : this.projectTaskService.getList(1, 20, term, this.queryParams.accountCenterCode)));
    }

    projectTaskFormatter = (projectTask: app.operation.ProjectTaskSelectListViewModel) => (projectTask.projectTaskNumber + " - " + projectTask.title);

    onProjectTaskChange($event: app.operation.ProjectTaskSelectListViewModel) {
        this.selectedProjectTask = $event;

        if (this.selectedProjectTask) {
            this.queryParams.projectTaskId = this.selectedProjectTask.firstProjectTaskId;
        } else {
            this.queryParams.projectTaskId = null;
            if (!this.queryParams.month) {
                const date = new Date();
                this.queryParams.month = date.getMonth() + 1;
            }
        }
    }

    onCountryCodeChange() {
        if (this.queryParams.accountCenterCode != null) {
            this.queryParams.countryCode = null;
        }
    }

    getReportUrl() {
        return this.projectTaskProductRateService.getInvoiceReport({ projectTaskIds: this.selectedItemsIds, sortBy: this.queryParams.sortBy });
    }

    getNotInvoicedReportUrl() {
        //toastr.info("Táto operácia môže dlhšie trvať, pre každú položku sa pozerá do sharepointu čas pracovného výkazu");
        return this.projectTaskProductRateService.getNotInvoicedProjectTaskReport({ projectTaskIds: this.selectedItemsIds, sortBy: this.queryParams.sortBy });
    }

    onAllCheckedChange() {
        this.isAllChecked = !this.isAllChecked;

        if (this.isAllChecked) {
            this.selectedItemsIds = this.model.data.map(x => x.projectTaskId);
        }
        else {
            this.selectedItemsIds = [];
        }
    }

    toggleCheck(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        const indexId = this.selectedItemsIds.indexOf(item.projectTaskId);

        if (indexId === -1) {
            this.selectedItemsIds.push(item.projectTaskId);
        } else {
            this.selectedItemsIds.splice(indexId, 1);
        }
    }

    isSelected(item: app.operation.ProjectTaskProductRateInvoiceViewModel) {
        return this.selectedItemsIds.indexOf(item.projectTaskId) >= 0;
    }

    onYearChange(year: string) {
        this.queryParams.year = parseInt(year);

        if (year && this.queryParams.month) {
            this.onMonthChange(this.queryParams.month);
        } else if (year) {
            this.queryParams.from = new Date(parseInt(year), 0, 1);
            this.queryParams.to = new Date(parseInt(year), 11, 31);
        } else {
            this.queryParams.month = null;
        }
    }

    onMonthChange(month: number) {
        this.queryParams.month = month;

        if (this.queryParams.month) {
            this.queryParams.from = new Date(this.queryParams.year, this.queryParams.month - 1, 1);
            let i = 31;
            do {
                this.queryParams.to = new Date(this.queryParams.year, this.queryParams.month - 1, i);
                i--;
            } while (this.queryParams.to.getMonth() != this.queryParams.month - 1);
        } else {
            this.onYearChange(this.queryParams.year.toString());
        }
    }

    onFromChange(from) {
        this.queryParams.from = from;
        this.queryParams.year = null;
        this.queryParams.month = null;
    }

    onToChange(to) {
        this.queryParams.to = to;
        this.queryParams.year = null;
        this.queryParams.month = null;
    }
}
