import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class InventoryService
    extends HttpService<app.operation.InventoryListViewModel, app.operation.InventoryFormViewModel, app.operation.InventoryInsertUpdateViewModel>
{
    objectKey = "op.Inventory";
    objectKeyTransfer = "op.InventoryTransfer";
    constructor(http: HttpClient) {
        super(http, "/api/operation/inventory/");
    }

    getFilter(): Observable<app.operation.InventoryListFilterViewModel> {
        return this.http.get<app.operation.InventoryListFilterViewModel>(this.url + "filter");
    }

    getHistory(page: number, pageSize: number, id: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + id + "/history", { params });
    }

    getReportUrl(params: any) {
        let parameters = this.getParameters(params);
        parameters.set("format", "xlsx");
        return this.url + `report?${parameters.toString()}`;
    }

    getNextInventoryNumber(accountCenterCode: string): Observable<string> {
        let params = new HttpParams();
        params = params.set("accountCenterCode", accountCenterCode);
        return this.http.get(this.url + "inventorynumber", { params, responseType: "text" });
    }

    getPhysicalLocationSelectList(searchTerm?: string): Observable<string[]> {

        if (searchTerm == null) {
            return new Observable<string[]>();
        }

        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }

        return this.http.get<string[]>(this.url + "physical", { params });
    }

    getIsResponsibleEmployee(employeeId: number): Observable<boolean> { 
        return this.http.get<boolean>(this.url + employeeId + "/responsible");
    }
}
